<template>
	<page-container :page="page">
		<video-header v-if="page.embedCode" :embed-code="page.embedCode" />
		<heading-image
			v-else-if="page.headingImage"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		/>
		<main-content
			:title="(page.title || page.header) ?? 'Page not found'"
			:subtitle="page.subtitle"
			:content="page.content"
			:images="page.sideImage"
		/>

		<contentblocks :items="page.contentblocks" />

		<section v-if="page.showGroupform" class="content-section section-reservations">
			<div class="row">
				<div class="columns column12">
					<div class="form-wrapper">
						<contact-form name="groups" :form="form" />
					</div>
				</div>
			</div>
		</section>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { locale } = useI18n();

await fetchPage();

const { data: form } = await useWebsiteFetch('groups', {
	query: { language: locale.value },
	key: `${locale.value}/groups`,
});
</script>

<style lang="scss" scoped>
form :deep(input:not([type='checkbox'], [type='radio'], [type='submit'])),
form :deep(select),
form :deep(textarea) {
	color: #000;
	border-color: #000;
}

form :deep(label),
form :deep(.formgroup-container .checkboxes label) {
	color: #000;
}
</style>
